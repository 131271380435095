import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Box, SortDirection, TableContainer } from "@material-ui/core";
import { ActivityNotifConfig, UpdateNotificationConfigResponse } from "@shipin/notifs-client/service";
import { toast } from "react-toastify";
import { isEqual } from "lodash";
import styled from "styled-components";

import { Table, TableBody, Button } from "ui";
import { notifsClient, queryKeys } from "config";
import { useNotificationConfiguration } from "queries";

import { rem } from "config/variable.styles";
import { Loader, Row, TableHeader } from "./NotificationConfigUtils";
import { ErrorToast } from "components/Toastbar";
import TablePlaceholder from "components/TablePlaceholder/TablePlaceholder";

export type NotificationSortBy = "severity" | "description" | "inApp" | "email" | "emailDaily" | "emailWeekly";
export type NotificationState = Record<string, ActivityNotifConfig>;

function comparator(a: ActivityNotifConfig, b: ActivityNotifConfig, sortBy: NotificationSortBy) {
  switch (sortBy) {
    case "severity":
      return a.severity.localeCompare(b.severity);
    case "description":
      return a.activityTitle.localeCompare(b.activityTitle);
    case "inApp":
      return a.inApp ? 1 : -1;
    case "email":
      return a.immediateEmail ? 1 : -1;
    case "emailDaily":
      return a.dailyEmail ? 1 : -1;
    case "emailWeekly":
      return a.weeklyEmail ? 1 : -1;
  }
}

const NotificationConfigDev = () => {
  const [height, setHeight] = useState(0);
  // Store initial state to handle reset button click
  const initialState = useRef<NotificationState>({});
  const [order, setOrder] = useState<SortDirection>("asc");
  const [state, setState] = useState<NotificationState>({});
  const [sortBy, setSortBy] = useState<NotificationSortBy>("severity");
  const values = Object.values(state);

  const client = useQueryClient();
  const { data, isFetching: isLoading, isError } = useNotificationConfiguration();
  const { mutate, isPending } = useMutation<UpdateNotificationConfigResponse, any, ActivityNotifConfig[]>({
    mutationFn: (config) =>
      notifsClient.updateNotificationConfig({
        conf: {
          activityNotifConfigs: config,
        },
      }).response,
    onSuccess: () => {
      client.setQueryData(queryKeys.notifications.config.queryKey, values);
      initialState.current = state;
    },
    onError: () => {
      setState(initialState.current);
      toast.error(<ErrorToast>Failed to update notification settings. Please try again in a few minutes.</ErrorToast>);
    },
  });

  useLayoutEffect(() => {
    const elementHeight = document.querySelector("#profile-page-root")?.clientHeight;

    if (elementHeight) {
      setHeight(elementHeight);
    }
  }, []);

  useEffect(() => {
    if (!data?.length) return;

    const newState = data.reduce<NotificationState>((acc, curr) => {
      acc[curr.activityTitle] = curr;
      return acc;
    }, {});

    setState(newState);
    initialState.current = newState;
  }, [data]);

  const notificationsConfig = values.sort((a, b) => {
    const result = comparator(a, b, sortBy);
    return order === "asc" ? result : -result;
  });

  // Check if all notifications are checked
  // using this in the table header
  // We can't calculate this with a single loop
  // If we try it using reduce, it requires initial values to be set true
  // It keeps checkbox checked initially for a few milliseconds
  const checked = {
    dailyEmail: notificationsConfig.length > 0 && notificationsConfig.every((config) => config.dailyEmail),
    inApp: notificationsConfig.length > 0 && notificationsConfig.every((config) => config.inApp),
    immediateEmail: notificationsConfig.length > 0 && notificationsConfig.every((config) => config.immediateEmail),
    weeklyEmail: notificationsConfig.length > 0 && notificationsConfig.every((config) => config.weeklyEmail),
  };

  const onChange = useCallback((value: ActivityNotifConfig) => {
    setState((prev) => ({
      ...prev,
      [value.activityTitle]: value,
    }));
  }, []);

  // eslint-disable-next-line
  const disabled = useMemo(() => isEqual(Object.values(state), Object.values(initialState.current)), [state, initialState.current]);

  const renderTable = () => {
    if (isLoading) {
      return Array.from({ length: 20 }).map((_, index) => <Loader key={index} />);
    }

    return notificationsConfig.map((rowOptions, index) => <Row key={index} {...rowOptions} onChange={onChange} />);
  };

  return (
    <>
      <TableContainer
        style={{
          maxHeight: height - 190,
          minHeight: height - 190,
        }}
      >
        <Table stickyHeader>
          <TableHeader
            loading={isLoading}
            sortBy={sortBy}
            order={order}
            onSort={(order, sortBy) => {
              setOrder(order);
              setSortBy(sortBy);
            }}
            checked={checked}
            onCheck={(key) => {
              setState((prev) => {
                // Avoiding Object.fromEntries for better code readability
                const newState = Object.values(prev).map((value) => ({
                  ...value,
                  [key]: !checked[key],
                }));

                return newState.reduce<NotificationState>((acc, curr) => {
                  acc[curr.activityTitle] = curr;
                  return acc;
                }, {});
              });
            }}
            disabled={notificationsConfig.length === 0}
          />
          <TableBody>{renderTable()}</TableBody>
        </Table>
        {(!data || data?.length === 0) && !isLoading && <TablePlaceholder style={{ minHeight: 280 }} isError={isError} />}
      </TableContainer>
      {!!notificationsConfig.length ? (
        <Box className="btns" display="flex" my={rem(25)} justifyContent="center" gridGap={rem(21)}>
          <Button variant="secondary" disabled={disabled || isPending} onClick={() => setState(initialState.current)}>
            Cancel
          </Button>
          <Button disabled={disabled} onClick={() => mutate(Object.values(state))} loading={isPending}>
            Save Changes
          </Button>
        </Box>
      ) : null}
    </>
  );
};

const Error = () => (
  <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M74.9865 45.1726V33.8139H66.5096C65.7959 30.407 64.4493 27.229 62.6045 24.4079L68.6036 
      18.4087L60.5711 10.3762L54.5719 16.3753C51.7508 14.5305 48.5728 13.1839 45.1659 12.4702V4H33.8072V12.4769C30.4003 
      13.1906 27.2223 14.5372 24.4011 16.3821L18.402 10.3829L10.3695 18.4155L16.3686 24.4146C14.5238 27.2358 13.1771 
      30.4138 12.4634 33.8207H4V45.1793H12.4769C13.1906 48.5863 14.5372 51.7642 16.3821 54.5854L10.3829 60.5845L18.4155 
      68.6171L24.4146 62.6179C27.2358 64.4628 30.4138 65.8094 33.8207 66.5231V75H45.1793V66.5231C48.5863 65.8094 51.7642 
      64.4628 54.5854 62.6179L60.5845 68.6171L68.6171 60.5845L62.6179 54.5854C64.4628 51.7642 65.8094 48.5863 66.5231 
      45.1793H75L74.9865 45.1726Z"
      fill="white"
      stroke="#040066"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M36.8189 44.7658L35.0955 21.5H43.9048L42.1814 44.7658H36.8189Z" fill="#00B4DE" stroke="#00B4DE" />
    <circle cx="39.9495" cy="54.2532" r="5.39241" fill="#00B4DE" />
  </svg>
);

const ErrorMsg = styled.div`
  font-size: ${rem(16)};
  color: black;
  font-weight: 400;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${rem(16)};
  p {
    line-height: ${rem(24)};
  }
  strong {
    font-weight: 600;
  }
`;

const NotificationConfig = () => {
  const [height, setHeight] = useState(0);

  useLayoutEffect(() => {
    const elementHeight = document.querySelector("#profile-page-root")?.clientHeight;

    if (elementHeight) {
      setHeight(elementHeight - 150);
    }
  }, []);

  return (
    <ErrorMsg style={{ height }}>
      <Error />
      <div>
        <p>
          The notification configuration page is temporarily disabled due to <strong>Technical Issues.</strong>
        </p>
        <p>We apologize for the inconvenience.</p>
      </div>
    </ErrorMsg>
  );
};

export default NotificationConfig;
